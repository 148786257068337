import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import 'unnamed'
import SEO from 'components/common/Seo'
import '../../static/css/bootstrap4.min.css'
import '../../static/css/style.css'
import logo_img from '../../static/img/my_id_logo.png'
import Context from '../common/Context'
import { Button } from '@material-ui/core'
import { IntlProvider } from 'react-intl'
import * as messages from '../../locales'

export default ({ children }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  const { user, dispatchUserAction } = useContext(Context)

  const changeLocale = value => () => {
    localStorage.setItem('locale', value)
    dispatchUserAction({
      type: 'CHANGE_LOCALE',
      payload: value,
    })
  }

  const user_locale = user ? user.locale : 'uz'
  return (
    <IntlProvider locale={user_locale} messages={messages[user_locale]}>
      <SEO
        title="Login page"
        keywords={[`gatsby`, `application`, `react`]}
        site={site}
      />
      <div className="wrapper">
        <section className="simple_block">
          <div className="has_width">
            <div className="row add_account">
              <div className="col-md-12">
                <a href="/">
                  <img src={logo_img} alt="" style={{ margin: '30px 0' }} />
                </a>
                <div className="d-flex justify-content-center mb-3">
                  <Button
                    color="primary"
                    onClick={changeLocale('uz')}
                    variant={user_locale === 'uz' ? 'contained' : 'outlined'}
                  >
                    Ўзбекча
                  </Button>
                  <Button
                    color="primary"
                    onClick={changeLocale('ru')}
                    variant={user_locale === 'ru' ? 'contained' : 'outlined'}
                  >
                    Русский
                  </Button>
                </div>

                {children}
              </div>
            </div>
          </div>
        </section>
        <div className="footer">
          <div className="footer_info">
            {new Date().getFullYear()} Информационная система биометрической
            идентификации || Единый интегратор UZINFOCOM
          </div>
        </div>
      </div>
    </IntlProvider>
  )
}
