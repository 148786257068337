export const ru = {
  scanQR: 'Отсканируйте QR код',
  loadingQR: 'Загрузка QR...',
  firstStep: '1. Откройте MyID с телефона.',
  secondStep:
    '2. Зайдите в раздел «Вход по QR» (нижняя правая иконка) и нажмите на кнопку Сканировать.',
  thirdStep: '3. Наведите камеру телефона на этот QR код.',
  back: 'Вернутся на сайт',
  'status-11': 'Перенаправление',
  'status-5': 'Выполните действие на вашем мобильном приложении',
  'status-7': 'Вы отменили авторизацию',
}
