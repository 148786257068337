export const uz = {
  scanQR: 'QR кодни сканерланг',
  loadingQR: 'QR юкланмоқда...',
  firstStep: '1. Телефонингизда MyID иловасини очинг.',
  secondStep:
    '2. MyID иловасидаги “QR орқали кириш” бўлимига (қуйи ўнг томондаги бўлим) киринг ва Сканерлаш тугмасини босинг.',
  thirdStep: '3. Телефонингиз камерасини ушбу QR кодга қаратинг.',
  back: 'Сайтга қайтиш',
  'status-11': 'Қайта йўналтириш',
  'status-5': 'Мобил иловангизда амални бажаринг',
  'status-7': 'Сиз авторизацияни бекор қилдингиз',
}
